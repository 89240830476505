<template>
  <div class="pl-4">
    <v-row
      align="center"
      class="mt-8 mb-4 pl-5"
      style="border-bottom:none!important"
    >
      多因子认证策略 &nbsp; <v-divider class="ml-3"></v-divider>
    </v-row>
    <v-row class="pl-16 align-center">
      <v-col cols="2">
        启用多因子认证
      </v-col>
      <v-col>
        <v-switch
          inset
          hide-details="true"
          dense
          v-model="enabled"
          :loading="loading"
          @click.stop="change(item)"
        ></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AditionalFactor",
  data() {
    return {
      enabled: false,
      loading: false
    };
  }
};
</script>
