<template>
  <div class="SourceList">
    <Breadcrumbs class="main-top"> </Breadcrumbs>
    <v-alert
      outlined
      dense
      text
      type="info"
      icon="mdi-lightbulb-on-outline"
      class="text-left rounded-sm body-2 pa-4 content-wrapper"
    >
      <span>多因子认证方式</span><br />
      通过IAM的多因素身份验证，在原本静态账号口令的基础上增加一层动态口令，或借助“扫一扫”、生物识别、手机APP推送等提升账号安全，有效解决静态密码被窃取后导致的非授权访问问题。
    </v-alert>

    <AditionalFactor></AditionalFactor>
    <CtrlFactor></CtrlFactor>
    <StandardFactor></StandardFactor>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import AditionalFactor from "./component/AditionalFactor";
import CtrlFactor from "./component/CtrlFactor";
import StandardFactor from "./component/StandardFactor";

export default {
  name: "SourceList",
  data() {
    return {};
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    }
  },
  components: {
    Breadcrumbs,
    AditionalFactor,
    CtrlFactor,
    StandardFactor
  }
};
</script>
