<template>
  <div class="pl-4">
    <v-row
      align="center"
      class="mt-8 mb-4 pl-5"
      style="border-bottom:none!important"
    >
      标准认证方式列表 &nbsp; <v-divider class="ml-3"></v-divider>
    </v-row>
    <v-list subheader two-line v-for="item in mfa_lists" :key="item.id">
      <v-list-item>
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark>
            {{ item.icon }}
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-switch
            inset
            hide-details="true"
            dense
            v-model="item.enabled"
            :loading="item.loading"
            :disabled="item.builtin"
            @click.stop="change(item)"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>

      <v-divider inset></v-divider>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "AditionalFactor",
  data() {
    return {
      mfa_lists: [
        {
          id: "wechat",
          icon: "mdi-wechat",
          title: "微信",
          enabled: false
        },
        {
          id: "wechat-enterprise",
          icon: "mdi-wechat",
          title: "企业微信",
          enabled: true
        }
      ]
    };
  }
};
</script>
