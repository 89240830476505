<template>
  <div class="pl-4">
    <v-row
      align="center"
      class="mt-8 mb-4 pl-5"
      style="border-bottom:none!important"
    >
      多因子认证方式列表 &nbsp; <v-divider class="ml-3"></v-divider>
    </v-row>
    <v-list subheader two-line v-for="item in mfa_lists" :key="item.id">
      <v-list-item>
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark>
            {{ item.icon }}
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>

          <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-switch
            inset
            hide-details="true"
            dense
            v-model="item.enabled"
            :loading="item.loading"
            :disabled="item.builtin"
            @click.stop="change(item)"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>

      <v-divider inset></v-divider>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "AditionalFactor",
  data() {
    return {
      mfa_lists: [
        {
          id: "security_keys",
          icon: "mdi-usb-flash-drive-outline",
          title: "安全密钥",
          subtitle: "使用webAuthn兼容的安全密钥",
          enabled: false
        },
        {
          id: "fido",
          icon: "mdi-fingerprint",
          title: "线上快速身份验证",
          subtitle: "使用符合webAuthn的设备生物识别",
          enabled: true
        },
        {
          id: "onetime_pwd",
          icon: "mdi-lock-outline",
          title: "一次性密码",
          subtitle: "使用Google Authenticator或类似工具提供一次性密码",
          enabled: false
        },
        {
          id: "auth0_guardian",
          icon: "mdi-shield-account",
          title: "通过auth0 guardian推送",
          subtitle: "使用guardian通过推送通知提供安全访问",
          enabled: false
        },
        {
          id: "phone",
          icon: "mdi-phone-outline",
          title: "手机信息",
          subtitle: "用户将收到包含验证码的短信或语音通话",
          enabled: false
        },
        {
          id: "email",
          icon: "mdi-email-outline",
          title: "邮箱",
          subtitle: "用户将收到一封包含验证码的电子邮件",
          enabled: false
        }
      ]
    };
  }
};
</script>
